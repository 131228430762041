export const WebRoutes = {
  HOME: "/",
  COMPANY: "/company",
  TRAVEL: "/travel",
  BANKING: "/banking",
  UTILITY: "/utility",
  INSURANCE: "/insurance",
  CSP: "/csp",
  SIGNUP: "/signup",
  ABOUT_US: "/aboutus",
  MEET_THE_TEAM: "/meettheteam",
  CAREERS: "/careers",
  SITE_MAP: "/sitemap",
  CASHDROP: "/cashdrop",
  LOGIN: "/login",
  CONTACT: "/contact",
  TERMS: "/terms",
  PRIVACY: "/privacy",
  GRIEVANCEPOLICY: "/grievancePolicy",
  TNCDMT: "/tncdmt",
  REFUNDPOLICY: "/refundPolicy",
};
