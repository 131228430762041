import React from "react";
import { Link } from "react-router-dom";
import "./team.css";
const Team = () => {
  return (
    <>
      <section className="OP_BannerT clearfix">
        <div className="container-fluid">
          <h2>
            Meet our Management Team
            <span>Meet the team behind DMT</span>
            <span>A perfect blend of creativity, technology and hardwork</span>
          </h2>
        </div>
      </section>

      <section id="MeetTheTeam">
        <div className="container">
          <div className="row wow fadeIn">
            <div className="col-md-6 wow fadeIn">
              <div className="teamFlex">
                <div className="ImgSection">
                  <img
                    src={require("../../static/img/team/member_1.png")}
                    className="img-fluid"
                    alt="Team"
                  />
                  <ul>
                    <li>
                      <Link to="https://twitter.com/Dmtmedia22">
                        <span
                          className="iconify"
                          data-icon="logos-twitter"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="mailto:contact@dmtgroup.in">
                        <span
                          className="iconify"
                          data-icon="logos-google-gmail"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.linkedin.com/in/premprakash-dmt/">
                        <span
                          className="iconify"
                          data-icon="cib-linkedin"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="ContentSectin">
                  <b>Prem Prakash</b>
                  <span>Chief Executive Officer</span>
                  <p>
                    Mr. Prem Prakash is the CEO of DMT grouo. Mr. Prem has
                    joined this company in 2018. He has fourteen years of
                    experience in the Travel and Technology Industry. Prior to
                    this company Mr. Prem has worked with leading travel
                    companies and has handled Sales and Marketing. He holds
                    Bachelor & Master degree in Marketing and Finance. Mr Prem
                    is responsible for overall business of the Company.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn">
              <div className="teamFlex">
                <div className="ImgSection">
                  <img
                    src={require("../../static/img/team/member_3.png")}
                    className="img-fluid"
                    alt="Team"
                  />
                  <ul>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-twitter"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-google-gmail"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="cib-linkedin"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="ContentSectin">
                  <b>Priti Kumari</b>
                  <span>Chief Financial Officer</span>
                  <p>
                    Ms. Priti Kumari is one of the founding members and is the
                    company’s Director Finance on the board of DMT group. She is
                    presently the head of Finance and Accounts which comprises
                    Finance, Secretarial, Internal Audit, Client Relations and
                    Management accounts. Priti Kumari joined the company in 2015
                    and carries vast experience in the areas of Strategy
                    planning and Finance.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn">
              <div className="teamFlex">
                <div className="ImgSection">
                  <img
                    src={require("../../static/img/team/member_1.png")}
                    className="img-fluid"
                    alt="Team"
                  />
                  <ul>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-twitter"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-google-gmail"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="cib-linkedin"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="ContentSectin">
                  <b>Somesh Kumar</b>
                  <span>Director Sales</span>
                  <p>
                    Mr. Somesh Kumar is the Director Sales on the board of DMT
                    group . Somesh has joined this company in the month of May
                    2018. He has seven years of experience in the Travel
                    Industry. Prior to this company Somesh has worked with
                    leading travel and Telecom companies. Somesh is responsible
                    for overall new acquisition & business development of B2B
                    and corporate clients.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 wow fadeIn">
              <div className="teamFlex">
                <div className="ImgSection">
                  <img
                    src={require("../../static/img/team/member_6.png")}
                    className="img-fluid"
                    alt="Team"
                  />
                  <ul>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-twitter"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-google-gmail"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="cib-linkedin"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="ContentSectin">
                  <b>Gautam Chatterjee</b>
                  <span>Vice President</span>
                  <p>
                    A senior level executive who have been consistently
                    contributing towards various organisational stints building
                    a highly comprehensive professional experience in Operations
                    & Process Management, Networking for Product Distribution,
                    Business Data Analysis, Marketing & Channel Management,
                    Monitoring of Regulatory Compliance, and much more.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn">
              <div className="teamFlex">
                <div className="ImgSection">
                  <img
                    src={require("../../static/img/team/member_6.png")}
                    className="img-fluid"
                    alt="Team"
                  />
                  <ul>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-twitter"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-google-gmail"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="cib-linkedin"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="ContentSectin">
                  <b>Deepak Kumar</b>
                  <span>Head Account & Finance</span>
                  <p>
                    Mr. Deepak Kumar is a Highly motivated and Goal-oriented
                    finance & Accounts professional. He has expertise in
                    financial strategy & analysis with numerous years of rich
                    experience. He is custodian of financial statement
                    preparation & review and stabilisation of Annual & Quarterly
                    Accounts
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn">
              <div className="teamFlex">
                <div className="ImgSection">
                  <img
                    src={require("../../static/img/team/member_6.png")}
                    className="img-fluid"
                    alt="Team"
                  />
                  <ul>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-twitter"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="logos-google-gmail"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="">
                        <span
                          className="iconify"
                          data-icon="cib-linkedin"
                          data-inline="false"
                        ></span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="ContentSectin">
                  <b>Syad Aslam</b>
                  <span>Head Operation</span>
                  <p>
                    Mr. Syed Aslam, an accomplished Operations Manager, brings
                    extensive experience in optimizing operational processes.
                    His strategic mindset and attention to detail drive
                    efficiency and excellence with strong leadership,
                    communication, and problem-solving skills.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Team;
